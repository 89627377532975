// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TokenInitializer from './TokenInitializer'; 
import CharacterSelect from './CharacterSelect';
import ListPage from './ListPage';  // リスト画面のコンポーネント
import GuidePage from './GuidePage'; 
import DiagnosisPage from './DiagnosisPage';
import EndingPage from './EndingPage';
import ExpiredPage from './ExpiredPage';
import { ServiceWorkerProvider } from './contexts/ServiceWorkerContext';  // 追加

function App() {
  return (
    <ServiceWorkerProvider>
      <Router>
        <Routes>
          <Route path="/:id" element={<TokenInitializer />} />
          <Route path="/character-select" element={<CharacterSelect />} />
          <Route path="/list" element={<ListPage />} />
          <Route path="/guide" element={<GuidePage />} />
          <Route path="/diagnosis" element={<DiagnosisPage />} />
          <Route path="/ending" element={<EndingPage />} />
          <Route path="/expired" element={<ExpiredPage />} />
        </Routes>
      </Router>
    </ServiceWorkerProvider>
  );
}

export default App;

