import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaRegPlayCircle, FaRegStopCircle } from 'react-icons/fa';
import { Circles } from 'react-loader-spinner';

Modal.setAppElement('#root'); // モーダルを使うために必要

function GuidePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const imageIndex = queryParams.get('image'); // クエリパラメータから画像のインデックスを取得

  // キャラクターごとの情報を保持するオブジェクト
  const characters = {
    1: {
      name: "一十木 音也",
      images: [
        "/images/guide/01/00_一十木音也pm.jpg",
        "/images/guide/01/01_一十木音也.jpg",
        "/images/guide/01/02_聖川真斗.jpg",
        "/images/guide/01/03_四ノ宮那月.jpg",
        "/images/guide/01/04_一ノ瀬トキヤ.jpg"
      ],
      audios: [
        "/audios/01_一十木音也pm.mp3",
        "/audios/01_一十木音也.mp3",
        "/audios/02_聖川真斗.mp3",
        "/audios/03_四ノ宮那月.mp3",
        "/audios/04_一ノ瀬トキヤ.mp3"
      ],
      choices: [
        ["元気な気分（仮）", "もやもやした気分（仮）"],
        ["ひまわり", "光", "ライオン"],
        ["回答A", "回答B", "回答C"],
        ["回答A", "回答B", "回答C"],
        ["回答A", "回答B", "回答C"]
      ],
      choices_detail: [
        [
          { img: "/images/choice_detail/01_01.jpg", text: "元気な気分（仮）に対応する詳細" },
          { img: "/images/choice_detail/01_02.jpg", text: "もやもやした気分（仮）に対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        // 他の選択肢詳細も同様に追加
      ]
    },
    2: {
      name: "聖川 真斗",
      images: [
        "/images/guide/02/00_聖川 真斗pm.jpg",
        "/images/guide/02/01_一十木音也.jpg",
        "/images/guide/02/02_聖川真斗.jpg",
        "/images/guide/02/03_四ノ宮那月.jpg",
        "/images/guide/02/04_一ノ瀬トキヤ.jpg"
      ],
      audios: [
        "/audios/02_聖川真斗pm.mp3",
        "/audios/01_一十木音也.mp3",
        "/audios/02_聖川真斗.mp3",
        "/audios/03_四ノ宮那月.mp3",
        "/audios/04_一ノ瀬トキヤ.mp3"
      ],
      choices: [
        ["回答＋", "回答−"],
        ["回答A", "回答B", "回答C"],
        ["回答A", "回答B", "回答C"],
        ["回答A", "回答B", "回答C"],
        ["回答A", "回答B", "回答C"]
      ],
      choices_detail: [
        [
          { img: "/images/choice_detail/02_01.jpg", text: "キャラ2の選択肢1に対応する詳細" },
          { img: "/images/choice_detail/02_02.jpg", text: "キャラ2の選択肢2に対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        [
          { img: "/images/choice_detail/01-A.ひまわり.jpg", text: "ひまわりに対応する詳細" },
          { img: "/images/choice_detail/01-B.光.jpg", text: "光に対応する詳細" },
          { img: "/images/choice_detail/01-C.ライオン.jpg", text: "ライオンに対応する詳細" }
        ],
        // 他の選択肢詳細も同様に追加
      ]
    }
    // 他のキャラクターの情報も同様に追加
  };

  const selectedCharacterData = JSON.parse(localStorage.getItem('selectedCharacters')) || { selectedId: 1, selectedName: '一十木 音也' };
  const character = characters[selectedCharacterData.selectedId];

  const [progress, setProgress] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ img: "", text: "" });
  const audioRef = useRef(new Audio());
  const [loading, setLoading] = useState(true); // ローディング状態の追加

  useEffect(() => {
    const storedProgress = localStorage.getItem('progress');
    const storedChoices = localStorage.getItem('selectedChoices');
    setProgress(storedProgress ? parseInt(storedProgress, 10) : 0);
    setSelectedChoices(storedChoices ? JSON.parse(storedChoices) : {});

    const audio = audioRef.current;
    audio.addEventListener('ended', handleAudioEnded);

    setLoading(false); // データの読み込みが完了したらローディングを終了

    return () => {
      audio.removeEventListener('ended', handleAudioEnded);
      audio.pause();
    };
  }, []);

  const handleChoiceSelect = (choiceIndex) => {
    const updatedChoices = { ...selectedChoices, [imageIndex]: choiceIndex };
    setSelectedChoices(updatedChoices);
    localStorage.setItem('selectedChoices', JSON.stringify(updatedChoices));
    localStorage.setItem('progress', imageIndex);
    setProgress(imageIndex);

    // 選択肢が2個の場合はモーダルを表示しない
    if (character.choices[imageIndex - 1].length > 2) {
      setModalContent(character.choices_detail[imageIndex - 1][choiceIndex]);
      setIsModalOpen(true);
    }
  };

  const playAudio = (audioUrl) => {
    const audio = audioRef.current;

    if (audio.src !== audioUrl) {
      audio.src = audioUrl;
      audio.currentTime = 0;
    }

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const selectedImage = character.images[parseInt(imageIndex, 10) - 1];
  const selectedAudio = character.audios[parseInt(imageIndex, 10) - 1];

  const isChoiceMade = selectedChoices[imageIndex] !== undefined;

  return (
    <div style={{ backgroundColor: '#000', color: 'yellow', minHeight: '100vh', padding: '20px', textAlign: 'center' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Circles color="#4fa94d" height={80} width={80} />
        </div>
      ) : (
        <>
          <Link to="/list" style={{
            position: 'fixed', top: '10px', left: '10px', display: 'inline-block',
            padding: '10px 20px 10px 35px', backgroundColor: 'transparent',
            color: 'yellow', fontSize: '16px', textDecoration: 'none', fontWeight: 'bold'
          }}>
            <span style={{ position: 'relative' }}>
              一覧に戻る
              <span style={{
                position: 'absolute', left: '-20px', top: '50%',
                transform: 'translateY(-50%)', width: '0', height: '0', 
                borderStyle: 'solid', borderWidth: '10px 10px 10px 0', 
                borderColor: 'transparent yellow transparent transparent'
              }}></span>
            </span>
          </Link>
          <h1></h1>
          <p>デバッグ用 選択された画像は設問 {imageIndex} です。</p>
          <img src={selectedImage} alt={`詳細画像 ${imageIndex}`} style={{ maxWidth: '100%', maxHeight: '400px' }} />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button onClick={() => playAudio(selectedAudio)} style={{
              margin: '10px',
              padding: '10px 20px',
              backgroundColor: '#000', 
              color: 'white', 
              border: '1px solid #fff', 
              borderRadius: '20px', 
              fontSize: '16px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              width: '50%', // ボタンの幅をコンテナの50%に設定
              minWidth: '250px', // ボタンの最小幅を150pxに設定
              justifyContent: 'center'
            }}>
              {isPlaying ? (
                <>
                  　ボイスを停止
                  <FaRegStopCircle style={{ marginLeft: '60px',fontSize: '30px' }} />
                </>
              ) : (
                <>
                  　ボイスを聴く
                  <FaRegPlayCircle style={{ marginLeft: '60px',fontSize: '30px' }} />
                </>
              )}
            </button>
          </div>

          <p style={{ color: 'yellow', textAlign: 'center' }}>ボイスを聴いたあとに<br />下記の選択肢から一つ選ぼう。</p>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            {character.choices[imageIndex - 1].map((choice, idx) => (
              <button key={idx} onClick={() => {
                if (selectedChoices[imageIndex] !== undefined) {
                  // 既に選択済みのボタンがタップされた場合のみモーダルを表示
                  if (character.choices[imageIndex - 1].length > 2) {
                    setModalContent(character.choices_detail[imageIndex - 1][idx]);
                    setIsModalOpen(true);
                  }
                  return;
                }
                handleChoiceSelect(idx);
              }} disabled={selectedChoices[imageIndex] !== undefined && selectedChoices[imageIndex] !== idx} style={{
                margin: '10px',
                padding: '10px 20px',
                backgroundColor: selectedChoices[imageIndex] === idx ? 'yellow' : '#000', 
                color: selectedChoices[imageIndex] === idx ? 'black' : '#fff', 
                border: '1px solid #fff', 
                borderRadius: '20px', 
                fontSize: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                width: '50%', // ボタンの幅をコンテナの50%に設定
                minWidth: '250px', // ボタンの最小幅を150pxに設定
                justifyContent: 'center'
              }}>
                {choice}
              </button>
            ))}
          </div>
          <Link to="/list" style={{
            display: 'inline-block',
            padding: '10px 20px', 
            backgroundColor: 'transparent',
            color: 'yellow', 
            fontSize: '16px', 
            textDecoration: 'none', 
            fontWeight: 'bold',
            textAlign: 'center', // テキストを中央寄せに
            marginTop: '20px' // 上部にマージンを追加
          }}>
            <span style={{
              position: 'relative',
              display: 'inline-block',
              marginRight: '20px' // アイコンとテキストの間隔調整
            }}>
              <span style={{
                position: 'absolute',
                left: '-20px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '0',
                height: '0',
                borderStyle: 'solid',
                borderWidth: '5px 0 5px 8.7px', // 大きさ調整
                borderColor: 'transparent transparent transparent yellow' // 矢印の色
              }}></span>
              一覧に戻る
            </span>
          </Link>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="選択肢モーダル"
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)'
              },
              content: {
                color: 'yellow',
                backgroundColor: '#000',
                borderRadius: '10px',
                padding: '20px',
                textAlign: 'center'
              }
            }}
          >
            <h2>選択肢が選ばれました</h2>
            {modalContent.img && <img src={modalContent.img} alt="選択肢の詳細画像" style={{ maxWidth: '100%', maxHeight: '300px' }} />}
            <p>{modalContent.text}</p>
            <button onClick={closeModal} style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: 'yellow',
              color: '#000',
              border: 'none',
              borderRadius: '20px',
              cursor: 'pointer'
            }}>
              閉じる
            </button>
          </Modal>
        </>
      )}
    </div>
  );
}

export default GuidePage;
