// src/contexts/ServiceWorkerContext.js
import React, { createContext, useEffect, useState, useCallback } from 'react';

const ServiceWorkerContext = createContext();

export const ServiceWorkerProvider = ({ children }) => {
  const [precacheComplete, setPrecacheComplete] = useState(false);
  const [isCaching, setIsCaching] = useState(false); // キャッシュ更新の実行中フラグ

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'PRECACHE_COMPLETE') {
          setPrecacheComplete(true);
        }
      });

      if (navigator.serviceWorker.controller) {
        setPrecacheComplete(true);
      } else {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          setPrecacheComplete(true);
        });
      }
    }
  }, []);

  const cacheCharacterFiles = useCallback(async (character) => {
    if (isCaching) {
      console.log('Cache update already in progress');
      return;
    }
    setIsCaching(true);

    try {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        const cache = await caches.open('character-cache');
        const filesToCache = getFilesForCharacter(character);

        const cacheChecks = await Promise.all(
          filesToCache.map(async (file) => {
            const match = await cache.match(file);
            return match ? true : false;
          })
        );

        const allFilesCached = cacheChecks.every((cached) => cached);

        if (!allFilesCached) {
          navigator.serviceWorker.controller.postMessage({
            type: 'CACHE_CHARACTER_FILES',
            character: character,
          });
        }
      }
    } finally {
      setIsCaching(false);
    }
  }, [isCaching]);

  const getFilesForCharacter = (character) => {
    const characterFiles = {
      'character1': ['/videos/types/01/01_一十木 音也.mp4', '/videos/types/01/02_聖川 真斗.mp4'],
      'character2': ['/videos/types/02/01_一十木 音也.mp4', '/videos/types/02/02_聖川 真斗.mp4'],
      // 他のキャラクターのファイルを追加
    };

    return characterFiles[character] || [];
  };

  return (
    <ServiceWorkerContext.Provider value={{ precacheComplete, cacheCharacterFiles }}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export default ServiceWorkerContext;
