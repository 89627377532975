import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';

function DiagnosisPage() {
  const [diagnosisResult, setDiagnosisResult] = useState('');
  const [image, setImage] = useState('');
  const [video, setVideo] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const poster = '/images/types/poster/映像用.jpg';

  useEffect(() => {
    const choicesData = localStorage.getItem('selectedChoices');
    if (choicesData) {
      const choices = JSON.parse(choicesData);
      const result = calculateDiagnosis(choices);
      setDiagnosisResult(result);
      updateMedia(result);
    }
  }, []);

  useEffect(() => {
    if (diagnosisResult && image && video) {
      setLoading(false);
    }
  }, [diagnosisResult, image, video]);

  function calculateDiagnosis(choices) {
    const firstQuestionResult = choices['1'] === 0 ? '+' : '-';
    const counts = { 0: 0, 1: 0, 2: 0 };
    Object.keys(choices).forEach(key => {
      if (key !== '1' && choices[key] !== undefined) {
        counts[choices[key]]++;
      }
    });
    let maxCount = Math.max(counts[0], counts[1], counts[2]);
    let types = [];
    Object.keys(counts).forEach(type => {
      if (counts[type] === maxCount) {
        types.push(type);
      }
    });
    const typeResult = types.length > 1 ? 'D' : String.fromCharCode('A'.charCodeAt(0) + parseInt(types[0], 10));
    return `${typeResult}${firstQuestionResult}`;
  }

  function updateMedia(result) {
    const selectedCharacterData = JSON.parse(localStorage.getItem('selectedCharacters')) || { selectedId: 1, selectedName: '一十木 音也' };
    const characterId = selectedCharacterData.selectedId;

    const mediaMap = {
      1: {
        'A+': { image: '/images/types/01/Ap.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'A-': { image: '/images/types/01/A-.jpg', video: '/videos/types/01/02_聖川 真斗.mp4' },
        'B+': { image: '/images/types/01/Bp.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'B-': { image: '/images/types/01/B-.jpg', video: '/videos/types/01/02_聖川 真斗.mp4' },
        'C+': { image: '/images/types/01/Cp.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'C-': { image: '/images/types/01/C-.jpg', video: '/videos/types/01/02_聖川 真斗.mp4' },
        'D+': { image: '/images/types/01/Dp.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'D-': { image: '/images/types/01/D-.jpg', video: '/videos/types/01/02_聖川 真斗.mp4' }
      },
      2: {
        'A+': { image: '/images/types/02/Ap.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'A-': { image: '/images/types/02/A-.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'B+': { image: '/images/types/02/Bp.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'B-': { image: '/images/types/02/B-.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'C+': { image: '/images/types/02/Cp.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'C-': { image: '/images/types/02/C-.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'D+': { image: '/images/types/02/Dp.jpg', video: '/videos/types/01/01_一十木 音也.mp4' },
        'D-': { image: '/images/types/02/D-.jpg', video: '/videos/types/01/01_一十木 音也.mp4' }
      }
    };

    const media = mediaMap[characterId][result] || { image: '/default_image.jpg', video: '/default_video.mp4' };
    setImage(media.image);
    setVideo(media.video);
    console.log(`Video URL set to: ${media.video}`);
  }

  function handleVideoTap() {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      videoElement.play().catch(error => {
        console.log('Failed to play the video:', error);
      });

      // フルスクリーンのリクエスト
      const requestFullscreen = videoElement.requestFullscreen || videoElement.webkitRequestFullscreen || videoElement.msRequestFullscreen;
      if (requestFullscreen) {
        requestFullscreen.call(videoElement).catch(error => {
          console.log('Failed to enter fullscreen:', error);
        });
      }
    }
  }

  function handleRestart() {
    navigate('/ending');
  }

  function handleExpired() {
    navigate('/expired');
  }

  return (
    <div style={{ backgroundColor: '#000', color: 'yellow', minHeight: '100vh', padding: '20px', textAlign: 'center' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Circles color="#4fa94d" height={80} width={80} />
        </div>
      ) : (
        <>
          <Link to="/list" style={{
            position: 'fixed', top: '10px', left: '10px', display: 'inline-block',
            padding: '10px 20px 10px 35px', backgroundColor: 'transparent',
            color: 'yellow', fontSize: '16px', textDecoration: 'none', fontWeight: 'bold'
          }}>一覧に戻る</Link>
          <h1></h1>
          <p>デバッグ用　あなたのタイプは {diagnosisResult} です。</p>
          <img src={image} alt={`タイプ ${diagnosisResult}`} style={{ width: '100%', maxHeight: '400px' }} />
          <video
            src={video}
            controls
            controlsList="nodownload"
            poster={poster}
            preload="auto"
            ref={videoRef}
            onLoadedData={() => setLoading(false)}
            onClick={handleVideoTap}
            onTouchStart={handleVideoTap}
            style={{ width: '100%' }}
          >
            お使いのブラウザは動画の再生をサポートしていません。
          </video>
          <button onClick={handleRestart} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', backgroundColor: 'yellow', color: 'black', borderRadius: '20px' }}>
            エンディングへ
          </button>
          <br />
          <button onClick={handleExpired} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', backgroundColor: 'yellow', color: 'black', borderRadius: '20px' }}>
            終了ページへ
          </button>
        </>
      )}
    </div>
  );
}

export default DiagnosisPage;
