import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function TokenInitializer() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    const init = async () => {
      try {
        // キャッシュストレージの削除
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(
            cacheNames.map(async (cacheName) => {
              const cache = await caches.open(cacheName);
              const requests = await cache.keys();
              if (requests.length > 0) {
                await caches.delete(cacheName);
                console.log(`Cache ${cacheName} deleted.`);
              }
            })
          );
        }

        // 初期化処理
        console.log("localStorage_clear");
        localStorage.clear();

        // 初期化後にキャラクター選択画面に遷移
        console.log("Navigating to /character-select");
        navigate('/character-select');

      } catch (error) {
        console.error('Initialization failed:', error);
      }
    };

    init();
  }, [navigate]);

  return (
    <div>
      <h1>Welcome to the page with token {token}</h1>
    </div>
  );
}

export default TokenInitializer;
