import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';

function ExpiredPage() {
  const [loading, setLoading] = useState(true);
  const imageUrl = '/images/0.メイン画像.jpg'; // ここに画像のパスを入力してください

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => setLoading(false);
  }, [imageUrl]);

  if (loading) {
    return (
      <div style={{ backgroundColor: '#000', color: 'white', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Circles
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#000', color: 'white', minHeight: '100vh', padding: '20px', textAlign: 'center' }}>
      <img src={imageUrl} alt="終了画像" style={{ width: '100%', maxHeight: '625px', marginBottom: '20px' }} />
      <p>利用時間は終了したけど、</p>
      <p>今日は来てくれてうれしかったよ。</p>
      <Link to="/character-select" style={{ 
        display: 'inline-block', 
        padding: '10px 20px', 
        backgroundColor: 'yellow', 
        color: 'black', 
        fontSize: '16px', 
        textDecoration: 'none', 
        fontWeight: 'bold',
        borderRadius: '20px',
        marginTop: '20px'
      }}>
        キャラ選択に戻る（テスト版のみ表示）
      </Link>
    </div>
  );
}

export default ExpiredPage;
