// src/ListPage.js
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import ServiceWorkerContext from './contexts/ServiceWorkerContext';

function ListPage() {
  const { precacheComplete, cacheCharacterFiles } = useContext(ServiceWorkerContext);
  const [selectedName, setSelectedName] = useState('');
  const [progress, setProgress] = useState(0); // 進捗を管理する状態変数
  const [loading, setLoading] = useState(true); // ローディング状態の追加

  useEffect(() => {
    const storedData = localStorage.getItem('selectedCharacters');
    if (storedData) {
      const data = JSON.parse(storedData);
      setSelectedName(data.selectedName);

      const character = `character${data.selectedId}`;
      /*if (precacheComplete) {
        cacheCharacterFiles(character);
      }*/
    }

    const storedProgress = localStorage.getItem('progress');
    if (storedProgress) {
      setProgress(parseInt(storedProgress, 10));
    }
    setLoading(false); // データの読み込みが完了したらローディングを終了
  }, [precacheComplete, cacheCharacterFiles]);

  const characterImages = {
    "一十木 音也": [
      { normal: "/images/list/01_一十木音也pm.jpg", locked: "/images/list/01_一十木音也pm.jpg" },
      { normal: "/images/list/01_一十木音也.jpg", locked: "/images/list/01_一十木音也.jpg" },
      { normal: "/images/list/02_聖川真斗.jpg", locked: "/images/list/02_聖川真斗.jpg" },
      { normal: "/images/list/03_四ノ宮那月.jpg", locked: "/images/list/03_四ノ宮那月.jpg" },
      { normal: "/images/list/04_一ノ瀬トキヤ.jpg", locked: "/images/list/04_一ノ瀬トキヤ.jpg" },
      { normal: "/images/list/05_神宮寺レン.jpg", locked: "/images/list/05_神宮寺レン.jpg" },
      { normal: "/images/list/06_来栖翔.jpg", locked: "/images/list/06_来栖翔.jpg" },
      { normal: "/images/list/07_愛島セシル.jpg", locked: "/images/list/07_愛島セシル.jpg" },
      { normal: "/images/list/08_寿嶺二.jpg", locked: "/images/list/08_寿嶺二.jpg" },
      { normal: "/images/list/09_黒崎蘭丸.jpg", locked: "/images/list/09_黒崎蘭丸.jpg" },
      { normal: "/images/list/10_美風藍.jpg", locked: "/images/list/10_美風藍.jpg" },
      { normal: "/images/list/11_カミュ.jpg", locked: "/images/list/11_カミュ.jpg" },
      { normal: "/images/list/01_一十木音也_タイプ診断.jpg", locked: "/images/list/01_一十木音也_タイプ診断.jpg" }
    ],
    "聖川 真斗": [
      { normal: "/images/list/02_聖川真斗pm.jpg", locked: "/images/list/02_聖川真斗pm.jpg" },
      { normal: "/images/list/01_一十木 音也.jpg", locked: "/images/list/01_一十木 音也.jpg" },
      { normal: "/images/list/02_聖川真斗.jpg", locked: "/images/list/02_聖川真斗.jpg" },
      { normal: "/images/list/03_四ノ宮那月.jpg", locked: "/images/list/03_四ノ宮那月.jpg" },
      { normal: "/images/list/04_一ノ瀬トキヤ.jpg", locked: "/images/list/04_一ノ瀬トキヤ.jpg" },
      { normal: "/images/list/05_神宮寺レン.jpg", locked: "/images/list/05_神宮寺レン.jpg" },
      { normal: "/images/list/06_来栖翔.jpg", locked: "/images/list/06_来栖翔.jpg" },
      { normal: "/images/list/07_愛島セシル.jpg", locked: "/images/list/07_愛島セシル.jpg" },
      { normal: "/images/list/08_寿嶺二.jpg", locked: "/images/list/08_寿嶺二.jpg" },
      { normal: "/images/list/09_黒崎蘭丸.jpg", locked: "/images/list/09_黒崎蘭丸.jpg" },
      { normal: "/images/list/10_美風藍.jpg", locked: "/images/list/10_美風藍.jpg" },
      { normal: "/images/list/11_カミュ.jpg", locked: "/images/list/11_カミュ.jpg" },
      { normal: "/images/list/02_聖川 真斗_タイプ診断.jpg", locked: "/images/list/02_聖川 真斗_タイプ診断.jpg" }
    ],
    // 他のキャラクター
  };

  const images = characterImages[selectedName] || [];

  return (
    <div style={{ backgroundColor: '#000', color: 'yellow', minHeight: '100vh', padding: '20px' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Circles color="#4fa94d" height={80} width={80} />
        </div>
      ) : (
        <>
          <Link to="/character-select" style={{
            position: 'fixed', top: '10px', left: '10px', display: 'inline-block',
            padding: '10px 20px 10px 35px', backgroundColor: 'transparent',
            color: 'yellow', fontSize: '16px', textDecoration: 'none', fontWeight: 'bold'
          }}>
            <span style={{ position: 'relative' }}>
              戻る
              <span style={{
                position: 'absolute', left: '-20px', top: '50%',
                transform: 'translateY(-50%)', width: '0', height: '0', 
                borderStyle: 'solid', borderWidth: '10px 10px 10px 0', 
                borderColor: 'transparent yellow transparent transparent'
              }}></span>
            </span>
          </Link>
          <h2 style={{ color: 'white', marginTop: '50px' }}>「{selectedName}」ボイスガイド</h2>
          <hr style={{ width: '100vw', marginLeft: '-20px', borderColor: 'white' }} />
          <p style={{ color: 'yellow', textAlign: 'center' }}>上から順番に進めていきます。<br />進めるごとに次の設問のロックが解除されます。</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {images.map((image, index) => (
              <div key={index} style={{ margin: '10px', border: '1px solid #fff', padding: '3px' }}>
                {index <= progress || (progress >= 5 && index === 12) ? (
                  <Link to={index === 12 ? "/diagnosis" : `/guide?image=${index + 1}`}>
                    <img src={image.normal} alt={`設問 ${index + 1}`} style={{ width: '100%', height: '100%' }} />
                  </Link>
                ) : (
                  <img src={image.locked} alt={`設問 ${index + 1}`} style={{
                    width: '100%', height: '100%', filter: 'grayscale(100%) opacity(50%)'
                  }} />
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ListPage;
