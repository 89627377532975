import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import ServiceWorkerContext from './contexts/ServiceWorkerContext';

function CharacterSelect() {
  const navigate = useNavigate();
  const { precacheComplete, cacheCharacterFiles } = useContext(ServiceWorkerContext);
  const [characters, setCharacters] = useState([
    { id: 1, name: "一十木 音也", selected: false, imageUrl: "/images/1.一十木音也.jpg", color: "#FF0000" },
    { id: 2, name: "聖川 真斗", selected: false, imageUrl: "/images/2.聖川 真斗.jpg", color: "#0070C0" },
    { id: 3, name: "四ノ宮 那月", selected: false, imageUrl: "/images/3.四ノ宮 那月.jpg", color: "#FFFF00" },
    { id: 4, name: "一ノ瀬 トキヤ", selected: false, imageUrl: "/images/4.一ノ瀬 トキヤ.jpg", color: "#7030A0" },
    { id: 5, name: "神宮寺 レン", selected: false, imageUrl: "/images/5.神宮寺 レン.jpg", color: "#FFC000" },
    { id: 6, name: "来栖 翔", selected: false, imageUrl: "/images/6.来栖 翔.jpg", color: "#FF99FF" },
    { id: 7, name: "愛島 セシル", selected: false, imageUrl: "/images/7.愛島 セシル.jpg", color: "#92D050" },
    { id: 8, name: "寿 嶺二", selected: false, imageUrl: "/images/8.寿 嶺二.jpg", color: "#00B050" },
    { id: 9, name: "黒崎 蘭丸", selected: false, imageUrl: "/images/9.黒崎 蘭丸.jpg", color: "#C00000" },
    { id: 10, name: "美風 藍", selected: false, imageUrl: "/images/10.美風 藍.jpg", color: "#CC99FF" },
    { id: 11, name: "カミュ", selected: false, imageUrl: "/images/11.カミュ.jpg", color: "#66FFFF" }
  ]);
  const [selectionDisabled, setSelectionDisabled] = useState(false);
  const [selectedCharacterId, setSelectedCharacterId] = useState(null);
  const [loading, setLoading] = useState(true); // ローディング状態の追加
  const [isCaching, setIsCaching] = useState(false); // キャッシュ更新の実行中フラグ

  useEffect(() => {
    const storedSelections = localStorage.getItem('selectedCharacters');
    if (storedSelections) {
      const selectedData = JSON.parse(storedSelections);
      setCharacters(characters.map(char => ({
        ...char,
        selected: char.id === selectedData.selectedId
      })));
      setSelectionDisabled(true);
      setSelectedCharacterId(selectedData.selectedId);
    }
    setLoading(false); // データの読み込みが完了したらローディングを終了
  }, []);

  const handleSelect = (id) => {
    if (selectionDisabled) return;
    setSelectedCharacterId(id);
    const newCharacters = characters.map(char => ({
      ...char,
      selected: char.id === id
    }));
    setCharacters(newCharacters);
  };

  const handleStart = async () => {
    const selectedCharacter = characters.find(char => char.id === selectedCharacterId);
    const character = `character${selectedCharacterId}`;

    if (selectedCharacterId === 1 || selectedCharacterId === 2) {
      setSelectionDisabled(true);
      localStorage.setItem('selectedCharacters', JSON.stringify({
        selectedId: selectedCharacterId,
        selectedName: selectedCharacter.name
      }));
      /*
      if (precacheComplete && !isCaching) {
        setIsCaching(true);
        await cacheCharacterFiles(character);
        setIsCaching(false);
      }*/

      navigate('/list', { state: { character } }); // リスト画面へ遷移
    } else {
      alert("選択されたキャラクターは開発中です。");
      setCharacters(characters.map(char => ({
        ...char,
        selected: false
      })));
      localStorage.removeItem('selectedCharacters');
      setSelectedCharacterId(null);
    }
  };

  return (
    <div style={{ backgroundColor: '#000', color: 'yellow', minHeight: '100vh', padding: '20px' }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Circles color="#4fa94d" height={80} width={80} />
        </div>
      ) : (
        <>
          <img src="/images/0.メイン画像.jpg" alt="Main Logo" style={{ width: '100%' }} />
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <p style={{ color: 'yellow', maxWidth: '600px', margin: '0 auto' }}>あなたと一緒に展示会巡るアイドルを</p>
            <p style={{ color: 'yellow', maxWidth: '600px', margin: '0 auto' }}>1名を選んでください</p>
            <p style={{ color: 'yellow', maxWidth: '600px', margin: '0 auto' }}>※再選択ができないためご注意ください</p>
            <p style={{ color: 'yellow', maxWidth: '600px', margin: '0 auto' }}>　</p>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {characters.map(char => (
              <div key={char.id} style={{ 
                margin: '5px', 
                flexBasis: 'calc(50% - 10px)', 
                border: char.selected ? `5px solid #ffffff` : `3px solid ${char.color}`,
                padding: '5px', 
                boxSizing: 'border-box', 
                transform: char.selected ? 'scale(1.1)' : 'none',
                transition: 'transform 0.3s, border 0.3s',
                opacity: selectionDisabled && !char.selected ? 0.5 : 1,
                cursor: selectionDisabled ? 'default' : 'pointer'
              }}>
                <img
                  src={char.imageUrl}
                  alt={char.name}
                  style={{ width: '100%' }}
                  onClick={() => handleSelect(char.id)}
                />
              </div>
            ))}
          </div>
          <hr style={{ width: '100vw', marginLeft: '-20px', padding: '-20px', borderColor: 'white' }} />
          <p style={{ color: 'white', textAlign: 'left' }}>
            下記を同意いただいたうえでご利用ください。<br />
            ‧ブラウザを閉じないでご利用ください。<br />
            ‧本サービスのご利用時間には制限がございます。<br />
            ご本人様以外への共有は禁止致します。<br />
            ‧ご利用中の通信費はお客様負担となります。<br />
            ‧本サービスの音声、画像、動画などのいかなるデータの保存、共有、複製及び解析等の行為は固く禁止致します。
          </p>
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <button style={{
              backgroundColor: 'yellow',
              color: 'black',
              borderRadius: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              width: '50%',
              minWidth: '250px'
            }} onClick={handleStart}>
              スタート
            </button>
          </div>
          <p style={{ color: 'yellow', textAlign: 'center' }}>
            スタートすると再選択はできません
          </p>
        </>
      )}
    </div>
  );
}

export default CharacterSelect;
