import React from 'react';
import { useNavigate } from 'react-router-dom';

function EndingPage() {
  const navigate = useNavigate();

  // ホームページに戻る関数
  function goToHomePage() {
    navigate('/character-select');  // ホームページへのパスを適宜調整してください
  }

  return (
    <div style={{ backgroundColor: '#000', color: 'white', minHeight: '100vh', padding: '20px', textAlign: 'center' }}>
      <h1>お疲れ様でした！</h1>
      <p>開発中</p>
      <button onClick={goToHomePage} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', backgroundColor: 'yellow', color: 'black', borderRadius: '5px' }}>
        キャラ選択に戻る
      </button>
    </div>
  );
}

export default EndingPage;
